import request from '../../util/request';
const version = 'v1';

export default {
  // 获取微信公众号签名信息及用户信息
  async getWxconfig (params) {
    return await request.get({ url: '/bff/api/getInfo', params, hideLoading: true });
  },

  // 分享
  pushShare(data) {
    return request.post( { url: `/bff/martech-sale/share/${version}/wx-share`, data, hideLoading: true });
  },
  // 获取内容
  async getContent (params) {
    return await request.get({ url: '/bff/api/getParams', params, hideLoading: true });
  },
};
