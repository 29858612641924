import sensors from 'wxb-web-sdk';
// import sensors from '../wxb-web-sdk';
import { deviceInfo, screenType } from '@/util/util';
// 埋点字段文档 https://docs.qq.com/sheet/DV1VQclB4Y1lnYUho?tab=lisy9f
// content_type	内容类型 枚举值：文章(article), 表单(form), 海报:(poster), 微传单(h5), 盖楼活动(building), 活动红包(packet)
let isHasCdp = null;
export default {
  // 埋点 初始化
  initTrace(paramsData, resultObj) {
    const { opId, tenantId, estateId, saleId, type } = paramsData.params;
    const cdpData = paramsData.cdp || {};
    // 没有返回能用参数，不上报数据
    isHasCdp = paramsData.cdp;
    // 公共字段
    const comData = {
      // time: new Date().getTime(), // 时间戳
      // ip: null, // ip
      // province: null,
      // manufacturer: null, // 设备制造商
      // carrier: null, // 运营商
      // city: null,
      screen_width: window.screen.width, // 屏幕高度
      screen_height: window.screen.height, // 屏幕宽度
      wifi: resultObj.wifi === 'wifi', // 是否 WIFI
      network_type: resultObj.wifi, // 网络类型
      model: deviceInfo.deviceType, // 设备型号
      os: deviceInfo.OS, // 操作系统
      os_version: deviceInfo.OSVersion, // 操作系统版本
      screen_orientation: screenType[deviceInfo.orientation], // 屏幕方向
      browser: deviceInfo.browserInfo.browser, // 浏览器
      browser_version: deviceInfo.browserInfo.browserVersion, // 浏览器版本
      name: paramsData.user.nickName,
      source_platform: 'WANGKE', // 来源平台
      corp_id: opId || null, // 运营主体ID
      renter_id: tenantId || null, // 租户ID
      project_id: estateId || null, // 项目ID
      saler_id: saleId || null, // 混合id(用户id$运营主休ID)
      content_type: type, // 类型
      ...cdpData //cdp接口返回什么就上报什么
    };
    if (!isHasCdp) {
      return;
    }

    let pointServerUrl = paramsData.baseConfig.pointUrl;
    //埋点sdk v1.1.4之后兼容card的v1.06之前线上配置的pointUrl，之后修改配置中心pointUrl后可去除此判断
    if (pointServerUrl.indexOf('/report') > -1) {
      pointServerUrl = pointServerUrl.replace('/report', '');
    }

    sensors.jsonInit({
      server_url: pointServerUrl,
      // server_url: 'https://secretdata.test.wangxiaobao.com/dc/api/v1/report',
      is_heart_beat: true,
      // heart_beat_time: 3000,
      distinct_id: paramsData.user.unionId,
      show_log: true,
      project: paramsData.baseConfig.pointName
    });

    // 使用 setProfile() 设置用户属性:is_login_id 标记 distinct_id 是否为登录id
    sensors.setProfile(paramsData.user.unionId, true, {});
    /**
     * @description 设置公共属性
     * @param {*} props object，必传
     */
    sensors.registerPage({
      ...comData
    });
  },
  // out追加属性方法
  viewOutTracePush(event, prop) {
    if (!isHasCdp) {
      return;
    }
    sensors.navigatorOutPushProp(event, {
      ...prop
    });
  },

  // 浏览名片
  contentViewTrace(paramsData, info, cb) {
    if (!isHasCdp) {
      return;
    }
    let id = info.aiCardId;
    let title = info.saleName;
    sensors.inViewReplaceAll(
      'content_read',
      {
        province: '',
        city: '',
        content_id: id,
        content_title: title,
        referrer_host: null,
        referrer: null,
        trace_id: paramsData.sessionId,
        url_path: window.location.hash.split('?')[0],
        share_trace_id: paramsData.params.shareTraceId || null,
        share_person: paramsData.params.sharedPeopleId || null
      },
      cb
    );
  },

  // 转发名片
  cardShareTrace(paramsData, info, type) {
    if (!isHasCdp) {
      return;
    }
    let id = info.aiCardId;
    let title = info.saleName;
    sensors.track('content_share', {
      count_key: `${paramsData.params.estateId}:${paramsData.user.unionId}:${paramsData.params.saleId}:${id}`,
      content_id: id,
      content_title: title,
      trace_id: paramsData.sessionId,
      share_type: type,
      share_depth: paramsData.params.depth || null
    });
  },
  // 转发内容
  // contentShareTrace(paramsData, info, type) {
  //   let id = info.aiCardId;
  //   let title = info.saleName;
  //   sensors.track('content_share', {
  //     province: paramsData.user.province,
  //     city: paramsData.user.city,
  //     content_id: id,
  //     content_title: title,
  //     trace_id: paramsData.sessionId,
  //     share_type: type,
  //     share_depth: paramsData.params.depth || null
  //   });
  // },
  // 点击事件
  btClickTrace(paramsData, info, bt_title, bt_id, cb) {
    if (!isHasCdp) {
      return;
    }
    let id = info.aiCardId;
    let title = info.saleName;
    sensors.track(
      'button_click',
      {
        button_id: bt_id, //'business_card_photo','business_card_video'
        button_title: bt_title, //名片_预览视频，名片_预览照片，
        button_type: '1',
        content_id: id,
        content_title: title,
        trace_id: paramsData.sessionId
      },
      cb
    );
  },

  //浏览完成事件
  viewExtraTrace(paramsData, cb) {
    if (!isHasCdp) {
      return;
    }
    const { saleName, cmeId, type } = paramsData.params;
    sensors.track(
      'content_read_completed',
      {
        trace_id: paramsData.sessionId,
        content_title: saleName,
        content_type: type,
        content_id: cmeId
      },
      cb
    );
  }
};
