/*
 * @Descripttion: -
 * @version: -
 * @Author: wjq
 * @Date: 2022-01-17 15:28:45
 * @LastEditors: wjq
 * @LastEditTime: 2022-01-18 19:06:12
 */
import request from '../../util/request';
const version = 'v1';
export default {
  //获取访问用户数量
  visitUvNum(data) {
    return request.post({url: `/bff/wangke/sale/uv`, data});
  },
  //获取访问用户
  visitUserList(data) {
    return request.post({url: `/bff/wangke/customer/trends`, data});
  },

  //获取在线咨询信息
  getCardChat(data) {
    return request.post({url: `/bff/martech-aggregation/card/v1/lex-card`, data});
  }
};
