<!--
 * @Descripttion: -
 * @version: -
 * @Author: wjq
 * @Date: 2022-01-17 15:28:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-04-12 15:07:41
-->
<template>
  <div class="card-view">
    <div class="card-head">
      <img class="top-bg" src="@/assets/images/bj-b@2x.png" alt="" />
<!--      <img v-if="chitchatQrCode" @click="showEwm" class="card-ewm" src="@/assets/images/erweima@2x.png" alt="" />-->
      <div class="cm-flex-row user-info">
        <img class="user-head" :src="content.avatar ? getUrl(content.avatar, 120) : require('@/assets/images/head@2x(1).png')" alt="" />
        <div class="cm-flex-col info-v">
          <div class="cm-flex-row name-v">
            <span class="name">{{ content.saleName || '待完善' }}</span>
            <div class="role-name">
              <!-- <img class="card-icon" src="@/assets/images/role@2x.png" alt="" /> -->
              <span>{{ content.post || '待完善' }}</span>
            </div>
          </div>
          <span class="desc">{{ content.slogan || '' }}</span>
        </div>
      </div>

      <div class="card-info">
        <div class="cm-flex-row visit">
          <div class="cm-flex-col visit-num">
            <span class="visit-num-text">看过TA</span>
            <div class="cm-flex-row visit-size">
              <span class="visit-num-size">{{ visitNumhSow }}</span>
              <span class="visit-num-unit">{{ unit }}</span>
            </div>
          </div>
          <div class="cm-flex-row visit-user">
            <img v-for="(item, index) in visitUser" :key="item + index" class="visit-head" :src="item.avatar || require('@/assets/images/head@2x(1).png')" mode="" />
            <img class="visit-head visit-head-more" src="@/assets/images/head-more@2x.png" mode="" />
          </div>
        </div>

        <div class="cm-flex-row user-contact">
          <div class="cm-flex-row user-contact-item" @click="copy(content.salePhone)">
            <span class="title">手机</span>
            <span class="value">{{ content.salePhone || '待完善' }}</span>
            <img v-if="content.salePhone" class="icon-copy" src="@/assets/images/icon-copy@2x.png" alt="" />
          </div>
          <div class="user-contact-item-line" />
          <div class="cm-flex-row user-contact-item" @click="copy(content.saleWx)">
            <span class="title-wx">微信</span>
            <span class="value">{{ content.saleWx || '待完善' }}</span>
            <img v-if="content.saleWx" class="icon-copy" src="@/assets/images/icon-copy@2x.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="content.briefTitle || content.brief" class="cm-flex-column desc-v">
      <img class="sanjiao" src="@/assets/images/sanjiaoxing@2x.png" alt="" />
      <div v-if="content.briefTitle" class="cm-flex-row desc-title">
        <img class="title-icon" src="@/assets/images/qipao@2x.png" alt="" />
        <span class="desc-title-big">{{ !content.briefTitle ? '顾问简介待完善' : content.briefTitle }}</span>
      </div>
      <div class="desc-content">
        <!-- <div> -->
        <span v-if="content.brief" :class="['desc-info', !showDesc ? 'hide-desc' : '']">{{ content.brief }}</span>
        <!-- </div> -->
        <!-- <div> -->
        <span class="desc-open" v-if="content.brief && content.brief.length > 104" @click="showDesc = !showDesc">{{ showDesc ? '收起' : '展开' }}</span>
        <!-- </div> -->
      </div>
    </div>

    <div class="cm-flex-column sand-v" v-if="isHasVr && vrs.length > 0">
      <div class="sand-title">
        <span class="sand-title-text">TA的沙盘</span>
        <img class="sand-title-img" src="@/assets/images/dibu@2x.png" alt="" />
      </div>
      <div class="sand-scroll-view">
        <div @click="vrClick(item)" :class="['sand-content', vrs.length === 2 ? 'sand-content-2' : '', vrs.length === 3 ? 'sand-content-3' : '']" v-for="(item, index) in vrs" :key="'sand' + index">
          <div class="sand-content-title">{{ item.title }}</div>
          <div class="sand-box">
            <img class="sand-img" :src="getUrl(item.imgUrl, 120)" alt="" />
            <img class="sand-logo" src="@/assets/images/vr-logo.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="cm-flex-column video-v" v-if="videos.length > 0">
      <div class="video-title">
        <span class="video-title-text">TA的视频</span>
        <img class="video-title-img" src="@/assets/images/dibu@2x.png" alt="" />
      </div>
      <div class="video-scroll-view">
        <div :class="['video-content', videos.length >= 2 ? 'video-more' : '']" v-for="(item, index) in videos" :key="'swiper_' + index" @click="swiperClick(index)">
          <div class="video-box">
            <img class="video-img" :src="item.url + video2Img" alt="" />
            <img class="video-logo" src="https://osres.wangxiaobao.com/ftd/wangke/h5/btn-play.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="cm-flex-column article-v" v-if="isHasCme && articles.length > 0">
      <div class="article-title">
        <span class="article-title-text">TA的文章</span>
        <img class="article-title-img" src="@/assets/images/dibu@2x.png" alt="" />
      </div>
      <div class="article-content" v-for="(item, index) in articles" :key="'article_' + index" @click="showArticle(item)">
        <div class="article-content-img">
          <img class="article-img" :src="getUrl(item.imgUrl, 120)" />
        </div>
        <div class="article-content-text">
          <span class="article-content-title">{{ item.title }}</span>
          <span class="article-content-abstract">{{ item.guideAbstract }}</span>
        </div>
      </div>
    </div>

    <div v-if="photos.length > 0" class="cm-flex-column photos-v">
      <div class="photos-title">
        <span class="photos-title-text">TA的相册</span>
        <img class="photos-img" src="@/assets/images/dibu@2x.png" />
      </div>
      <!-- <div class="photo" v-for="(item, index) in photos" :key="'photo_' + index" @click="showPhoto(getUrl(item.url))"></div> -->
      <img @click="showPhoto(getUrl(item.url))" :src="getUrl(item.url, 800)" v-for="(item, index) in photos" :key="'photo_2_' + index" class="photo" alt="" />
    </div>

    <div class="cm-flex-column project-v" v-if="isHasOnlineBuild">
			<div class="project-title">
				<span class="project-title-text">服务项目</span>
				<img class="project-title-img" src="@/assets/images/dibu@2x.png" />
			</div>
			<div class="project-content">
				<div class="project-img">
					<img class="img-view" :src="getUrl(onlineSale.coverImg, 256)" mode="aspectFill" :lazy-load="true"/>
				</div>
				<div class="project-info">
					<span class="info-title">{{onlineSale.estateShowName}}</span>
					<div class="info-price">
						<span class="price-text">约</span>
						<span class="price-text price-num">{{onlineSale.avPrice}}</span>
						<span class="price-text">均价</span>
						<span class="price-text price-unit">(元/m²)</span>
					</div>
					<span class="info-address">{{onlineSale.estateAddr}}</span>
					<div class="info-label">
						<span class="label-item" v-for="(item, index) in onlineSale.estateTags" :key="'project_'+index">{{item}}</span>
					</div>
				</div>

              <div class="pro-weapp">
                <wx-open-launch-weapp
                        id="launch-btn-pro"
                        :username="onlineSale.username"
                        :path="weappPath"
                        @error="handleErrorFn"
                        @launch="handleLaunchFn"
                >
                  <script type="text/wxtag-template">
                    <style>.maxbox-pro {width: 310px; height: 96px;}</style>
                    <div class="maxbox-pro"></div>
                  </script>
                </wx-open-launch-weapp>
              </div>
			</div>
    </div>

    <div v-if="photos.length > 0" class="no-more">没有更多了哦～</div>
    <ewm-card v-if="ewmPopShow" :show="ewmShow" :name="content.saleName" @close="closeEwm" :ewmUrl="chitchatQrCode" :head="content.avatar ? getUrl(content.avatar) : require('@/assets/images/head@2x(1).png')"></ewm-card>

    <error :errMsg="cardError" v-if="!isPreview && cardError" />

    <div v-show="content.salePhone && appType == '1'" class="cm-flex-col share-view">
      <!--<div class="share-top" />
      <img class="saler-img" v-if="isHasOnlineBuild" :src="onlineSale.coverImg"/>
      <img class="saler-img" v-else :src="content.avatar ? getUrl(content.avatar, 120) : require('@/assets/images/head@2x(1).png')" />-->
      <div class="share-body" :class="safeBottom ? 'safe-bottom' : ''">
        <div class="share-content">
          <div class="act-btn add-user"  v-if="chitchatQrCode" @click="showEwm">
            <img class="share-bt-icon" src="@/assets/images/jiahaoyou@2x.png" />
            <div class="text">加好友</div>
          </div>
          <div class="act-btn chitchat" v-if="cusServiceLink" @click="opChat">
            <img class="share-bt-icon" src="@/assets/images/goutong@2x.png" />
            <div class="text">在线咨询</div>
          </div>
          <!--<div class="cm-flex-col saler-info" >
            <div class="ground" @click="toTop">
              <div class="cm-flex-row name-v">
                <span class="name" v-if="isHasOnlineBuild">{{ onlineSale.estateShowName }}</span>
                <span class="name" v-else>{{ omitName || '待完善' }}</span>
                &lt;!&ndash; <div class="role-name">
                  <img class="icon" src="@/assets/images/role@2x.png" alt="" />
                  <span>{{content.post || '待完善'}}</span>
                </div> &ndash;&gt;
              </div>
              <div class="cm-flex-row name-bottom">
                <span class="name-bottom-text">查看信息</span>
                <img class="name-bottom-icon" src="@/assets/images/icon-right@2x.png" mode="" />
              </div>
            </div>
            <div class="open-weapp" v-if="isHasOnlineBuild">
              <wx-open-launch-weapp
                      id="launch-btn"
                      :username="onlineSale.username"
                      :path="weappPath"
                      @error="handleErrorFn"
                      @launch="handleLaunchFn"
              >
                  <script type="text/wxtag-template">
                    <style>.maxbox {width: 100%; height: 40px;}</style>
                    <div class="maxbox"></div>
                  </script>
              </wx-open-launch-weapp>
            </div>
          </div>-->
          <a class="share-bt-a" :href="'tel:' + content.salePhone">
            <div class="cm-flex-row share-bt">
              <img class="share-bt-icon" src="@/assets/images/call@2x.png" mode="" />
              <span class="share-bt-text">拨打电话</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <van-popup v-model="isPlayer" @close="closeVideo">
      <div class="cm-flex-row video-player-v">
        <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" @ready="playerReadied" :options="playerOptions"> </video-player>
      </div>
    </van-popup>

    <van-popup v-model="isPhotoShow" @close="closePhoto">
      <div class="cm-flex-row video-player-v">
        <img class="show-img" @click="closePhoto" :src="photoShowUrl" alt="" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player';
import 'vue-video-player/src/custom-theme.css';
import 'video.js/dist/video-js.css';

import cardApi from '@/api/card';
import comApi from '@/api/common';
import wxFn from '@/util/wxFn';
import ewmCard from '@/components/ewm-card';
import trackFn from '@/util/trackFn';
import md5 from "js-md5";
// baseConfig: {}, // 基础配置
// content: {}, // 名片内容
// params: {}, // 授权参数
// wxSign: {}, // 微信js-sdk签名
// user: {}, // 授权用户信息
export default {
  components: { ewmCard, videoPlayer },
  paramsData: {}, // 存放临时参数，不渲染
  data() {
    return {
      isFromPreview: false, // 是否是预览
      eventId: '',
      content: {}, // 名片内容
      omitName: '', // 截取后名字
      cmeId: '',
      extField: '',
      isPreview: false,
      cardError: false,
      cardMsg: '没有名片信息',
      trackDataTemp: {
        durationTimes: null,
        isFinished: null,
      },

      videoShowUrl: '',
      video2Img: '?x-oss-process=video/snapshot,t_1000,f_jpg,w_300,m_fast,ar_auto',
      visitNum: 0,
      visitUser: [],
      ewmPopShow: false,
      showDesc: false,
      videoBg: '',
      videos: [],
      articles: [],
      photos: [],
      vrs: [],
      isHasOnlineBuild: false,
      isHasVr: false,
      isHasCme: false,
      onlineSale: {},
      ewmShow: false,
      isPlayer: false,
      isPhotoShow: false,
      photoShowUrl: '',
      videoWidth: 0,
      videoHeight: 0,

      videoLoading: true,
      gotop: false,
      safeBottom: false,

      appType: '1',  //  是否隐藏底部导航条 1： 不隐藏  2： 隐藏
      estateId: '',
      weappShareId: '',

      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '1:1', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 类型
            src: '', // url地址
          },
        ],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },

      cusServiceLink: '',  //  IM 聊天地址
      chitchatQrCode: '',  //  二维码
    };
  },
  computed: {
    visitNumhSow() {
      return this.visitNum >= 10000 ? (this.visitNum / 10000).toFixed(1) : this.visitNum;
    },
    unit() {
      if (this.visitNum >= 10000) {
        return '万';
      } else {
        return '人';
      }
    },
    player() {
      return this.$refs.videoPlayer.player;
    },

    weappPath() {
      return `${this.onlineSale.path}?shareId=${this.weappShareId}&id=${this.estateId}`;
    },
  },
  beforeMount() {
    var userAgent = navigator.userAgent;
    console.log('设备信息', window.screen.width, window.screen.height);
    var isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isiOS) {
      var phoneWidth = window.screen.width;
      var phoneHeight = window.screen.height;
      // ios高宽比，接近1.9需要适配安全距离，接近1.6不需要适配
      var proportion = (phoneHeight / phoneWidth) * 10;
      proportion = Math.floor(proportion);
      this.safeBottom = proportion >= 19 ? true : false;
    } else {
      this.safeBottom = false;
    }
  },

  mounted() {
    let { cmeId, from, appType = '1', estateId = '' } = this.$route.query;
    this.videoWidth = document.body.clientWidth;
    this.videoHeight = document.body.clientHeight;
    this.isFromPreview = from == 'preview' ? true : false;
    this.appType = appType;
    this.estateId = estateId;
    if (cmeId) {
      this.init();
    }
    addEventListener('scroll', this.handleScroll); //监听函数

  },
  methods: {
    // 图片压缩地址
    getUrl(url, w = 800, q = 80) {
      return `${url}?x-oss-process=image/resize,w_${w}/quality,q_${q}`
    },
    handleScroll() {
      if (sessionStorage.getItem('viewEnd') === 'complete') return;
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        trackFn.viewExtraTrace(this.paramsData, () => {
          sessionStorage.setItem('viewEnd', 'complete');
        });
      }
    },
    async init() {
      try {
        let isFromPreview = this.isFromPreview;
        let tempData = {};
        if (isFromPreview) {
          tempData = await comApi.getContent();
        } else {
          // 获取基础配置、获取名片内容、参数信息、jssdk、授权信息
          let apiList = ['onMenuShareTimeline', 'onMenuShareAppMessage', 'getLocation'];
          let tagList = ['wx-open-launch-weapp'];
          tempData = await wxFn.init(apiList, tagList);
          this.chitchatQrCode = tempData.content.saleQrCode;
          try {
            let par = {
              cardId: tempData.content.saleId,
              unionId: tempData.user.unionId,
              nickName: tempData.user.nickName,
              avter: tempData.user.headImg,
              tenantId: tempData.params.tenantId,
              opId: tempData.params.opId,
              estateId: tempData.params.estateId,
              role: "ZYGW"
            };
            let {cusServiceLink, qwQrcode} = await cardApi.getCardChat(par);
            this.cusServiceLink = cusServiceLink;
            this.chitchatQrCode = qwQrcode;
          } catch (e) {
            console.log('err: ', e);
          }
        }

        if (tempData.msg) {
          throw new Error(tempData.msg);
        }
        const { content, params, visitNum, visitUser } = tempData;
        this.isHasOnlineBuild = tempData.isHasOnlineBuild;
        this.isHasVr = tempData.isHasVr;
        this.isHasCme = tempData.isHasCme;
        if (tempData.isHasOnlineBuild) {
          this.onlineSale = tempData.onlineSale;
          this.onlineSale.estateTags = this.onlineSale.estateTags.split(';');
          if (!isFromPreview) {
            this.weappShareId = tempData.params.weappShareId;
          }
        }
        this.paramsData = tempData;
        this.content = content;
        this.visitNum = Number(visitNum || '0');
        this.visitUser = visitUser || [];
        // 测试左下角名字超过四个截取的
        if (this.content.saleName.length >= 4) {
          let midleArr = content.saleName;
          this.omitName = midleArr.slice(0, 3) + '...';
        } else {
          this.omitName = this.content.saleName;
        }

        const title = content.saleName ? `${content.saleName}-${content.post}` : '旺客+';
        document.title = title;
        this.videos = content.attachVideo || [];
        this.articles = content.articles || [];
        this.photos = content.attachImg || [];
        this.vrs = content.vrs || [];
        this.videos[0] && (this.videoBg = this.videos[0].url + this.video2Img);

        //查询访问数据
        // this.visitUv();
        this.extField = JSON.stringify(params);
        this.pageOverLay = false;
        if (!isFromPreview) {
          // 分享
          let shareDesc = content.slogan || content.brief;
          wxFn.initWxShareShare(tempData, title, shareDesc, content.avatar, params.type, content);
          // 触发埋点 浏览
          let trackData = { ...content };
          // 预览名片
          trackFn.contentViewTrace(tempData, trackData, (e) => {
            console.log('IN事件Id-1->', e);
            this.eventId = e;
          });
        }

      } catch (error) {
        console.error('error', error);
        this.$toast(error.message || '网络错误');
      }
    },

    handleLaunchFn(e) {
      console.log("success", e);
      trackFn.btClickTrace(this.paramsData, this.content, `${this.onlineSale.estateShowName}项目`, 'business_card_project');
    },
    handleErrorFn(e) {
      console.log("fail", e.detail);
    },
    // 推送埋点数据补充 ： is_finished为true
    pushTrackData() {
      trackFn.viewOutTracePush('content_read', {
        is_finished: true,
      });
    },
    // async visitUv() {
    //   const { type } = this.paramsData.params;
    //   let res = await cardApi.visitUvNum({ content_type: type });
    //   if (res) {
    //     console.log(res.visits_uv);
    //     this.visitNum = res.visits_uv || 0;
    //   }

    //   let userList = await cardApi.visitUserList({ content_type: type, page_no: 1, page_size: 10, view_rule: 'new' });
    //   if (userList && userList.data) {
    //     this.visitUser = userList.data || [];
    //   }
    // },
    swiperClick(index) {
      this.videoShowUrl = this.videos[index].url;
      this.isPlayer = true;
      this.videoBg = this.videos[index].url + this.video2Img;
      this.playerOptions.poster = this.videoBg;
      this.playerOptions.sources[0].src = this.videoShowUrl;

      this.$set(this.playerOptions.sources, 0, {
        type: 'video/mp4', // 类型
        src: this.videoShowUrl,
      });
      // this.$refs.videoPlayer.player.src(this.videoShowUrl); // 重置进度条复制代码
      trackFn.btClickTrace(this.paramsData, this.content, '视频', 'business_card_video');
      // this.$refs.videoPlayer.player.play(); // 自动播放
      // this.$refs.videoPre.playVedio(this.videoShowUrl);
    },
    playerReadied() {
      this.player.play();
      // if (this.isPlayer) {
      //   this.player.play();
      // } else {
      //   this.player.pause();
      // }
    },
    // playerClose() {
    //   // this.isPlayer = false;
    //   this.player.pause(); // 暂停
    // },
    closeVideo() {
      // this.videoShowUrl = '';
      // this.videoBg = '';
      // this.playerOptions.poster = '';
      // this.playerClose();
      this.player.pause();
    },
    showArticle(item) {
      const jump = () => {
        window.location.href = item.previewUrl;
      };
      trackFn.btClickTrace(this.paramsData, this.content, '文章', 'business_card_article', jump);
    },
    showPhoto(url) {
      trackFn.btClickTrace(this.paramsData, this.content, '照片', 'business_card_photo');
      this.photoShowUrl = url;
      this.isPhotoShow = true;
    },
    closePhoto() {
      this.photoShowUrl = '';
      this.isPhotoShow = false;
    },
    showEwm() {
      this.ewmPopShow = true;
      this.ewmShow = true;
    },
    opChat() {
      window.location.href = this.cusServiceLink
    },
    closeEwm() {
      this.ewmShow = false;
      //u-pop动画执行是300ms,然后去掉pop组件（此组件影响页面滑动，后面优化）
      setTimeout(() => {
        this.ewmPopShow = false;
      }, 300);
    },
    toTop() {
      let top = document.body.scrollTop || document.documentElement.scrollTop;
      console.log('top---', top);
      //实现滚动效果
      const timeTop = setInterval(() => {
        top -= 50;
        if (document.body.scrollTop) {
          document.body.scrollTop = top;
        } else {
          document.documentElement.scrollTop = top;
        }
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    copy(text) {
      if (text) {
        var input = document.createElement('input');
        var currentFocus = document.activeElement;
        document.body.appendChild(input);
        input.style.position = 'fixed';
        input.style.top = 0;
        input.style.left = 0;
        input.style.background = 'transparent';
        input.readOnly = 'readonly';
        input.value = text;
        input.focus();
        if (input.setSelectionRange) input.setSelectionRange(0, input.value.length);
        else input.select();
        try {
          var msg = document.execCommand('copy') ? '成功' : '失败';
          this.$toast('复制' + msg);
        } catch (err) {
          this.$toast('当前版本无法复制');
        }
        input.blur();
        document.body.removeChild(input);
        currentFocus.focus();
        currentFocus.blur();
      }
    },
    vrClick(item) {
      const jump = () => {
        window.location.href = item.previewUrl;
      };
      trackFn.btClickTrace(this.paramsData, this.content, '微沙盘', 'business_card_vr', jump);
    },
  },
  destroyed() {
    removeEventListener('scroll');
  },
};
</script>

<style lang="less" scoped>
.item-content {
  position: relative;
  .btn-play {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
  }
}
.card-view {
  background: #f6f8f9;
  overflow-y: scroll;
  padding: 12px 0 90px 0;
  .card-head {
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    width: 363px;
    height: 210px;
    margin-left: 6px;

    .top-bg {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 363px;
      height: 155px;
      border-radius: 10px 10px 0px 0px;
    }

    .card-ewm {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 0px;
      right: 0px;
    }

    .user-info {
      position: relative;
      width: 315px;
      box-sizing: border-box;
      margin-left: 24px;
      margin-top: 20px;
      margin-bottom: 16px;
      margin-right: 24px;
      justify-content: flex-start;

      .user-head {
        object-fit: cover;
        margin-right: 10px;
        width: 48px;
        height: 48px;
        border: 2px solid #ffffff;
        border-radius: 50%;
        overflow: hidden;
      }

      .info-v {
        width: 200px;
        justify-content: center;
        .name-v {
          display: flex;
          align-items: center;
          // justify-content: center;
          .name {
            max-width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: keep-all;
            white-space: nowrap;
            text-align: left;
            font-size: 16px;
            color: #ffffff;
            line-height: 16px;
          }
          .role-name {
            height: 14px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: 6px;
            padding: 0 4px;
            background-color: #e9c797;
            border-radius: 6px;
            span {
              // height: 10px;
              font-size: 10px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #6a4e38;
              line-height: 10px;
              text-align: center;
            }
          }
        }

        .card-icon {
          width: 32px;
          height: 10px;
        }

        .desc {
          // width: 180px;
          text-align: left;
          margin-top: 3px;
          font-size: 10px;
          color: #ffffff;
        }
      }

      .edit-v {
        justify-content: center;
        position: absolute;
        top: 13px;
        right: 0px;
        width: 55px;
        height: 20px;
        background-color: rgba(149, 162, 184, 0.6);
        border-radius: 8px;

        .edit-icon {
          width: 8px;
          height: 8px;
        }

        .edit-text {
          font-size: 10px;
          line-height: 20px;
          margin-left: 3px;
          color: #ffffff;
        }
      }
    }

    .card-info {
      z-index: 1;
      background: linear-gradient(180deg, #edf3fd 0%, #ffffff 100%);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06), inset 1px 1px 0px 0px #ffffff;
      border-radius: 4px;
      opacity: 0.7;
      margin-left: 10px;
      width: 343px;
      height: 119px;
      box-sizing: border-box;
      padding-top: 20px;
      padding-right: 16px;
      padding-bottom: 0px;
      padding-left: 16px;

      .visit {
        align-items: flex-end;
        justify-content: space-between;
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 12px;

        .visit-num {
          .visit-num-text {
            font-size: 12px;
            color: #86858b;
            margin-bottom: 2px;
          }

          .visit-size {
            align-items: flex-end;

            .visit-num-size {
              font-size: 21px;
              font-weight: bold;
              color: #1d2541;
            }

            .visit-num-unit {
              font-size: 10px;
              line-height: 21px;
              color: #1d2541;
            }
          }
        }

        .visit-user {
          padding-left: 11px;

          .visit-head {
            width: 28px;
            height: 28px;
            border-width: 1px solid #fff;
            border-radius: 15px;
            margin-left: -9px;
            margin-top: 2px;
          }

          .visit-head-more {
            width: 29px;
            height: 29px;
            border-width: 1px solid #fff;
          }
        }
      }

      .user-contact {
        justify-content: space-between;
        height: 36px;
        align-items: center;
        .user-contact-item {
          align-items: center;
          width: 150px;
          overflow: hidden;
          /* break-all(允许在单词内换行。) */
          text-overflow: ellipsis;
          /** 对象作为伸缩盒子模型显示 **/
          -webkit-box-orient: vertical;
          .title {
            word-break: keep-all;
            font-size: 12px;
            margin-right: 3px;
            color: #86858b;
          }

          .title-wx {
            word-break: keep-all;
            margin-left: 10px;
            font-size: 12px;
            margin-right: 3px;
            color: #86858b;
          }

          .value {
            font-size: 12px;
            line-height: 18px;
            white-space: nowrap;
            max-width: 100px;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #1d2541;
          }
          .icon-copy {
            width: 16px;
            height: 12px;
            margin-left: 3px;
          }
        }

        .user-contact-item-line {
          width: 1px;
          height: 16px;
          background-color: #f5f5f5;
          border-radius: 1px;
        }
      }
    }
  }

  .desc-v {
    position: relative;
    box-sizing: border-box;
    width: 343px;
    min-height: 119px;
    margin-left: 16px;
    margin-top: 18px;
    background: #fff;
    border-radius: 8px;
    padding: 16px;

    .sanjiao {
      position: absolute;
      top: -4px;
      left: 18px;
      width: 9px;
      height: 4px;
    }

    .desc-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 343px;
      height: 119px;
    }

    .desc-title {
      .title-icon {
        width: 16px;
        height: 16px;
        margin-right: 7px;
      }

      .desc-title-big {
        width: 343px;
        text-align: left;
        // margin-left: 9px;
        font-size: 17px;
        font-weight: bold;
        color: #1d2541;
        line-height: 24px;
      }
    }

    .desc-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      .desc-info {
        display: block;
        width: 311px;
        white-space: pre-wrap;
        font-size: 12px;
        margin-top: 12px;
        color: #86858b;
        line-height: 20px;
      }

      .hide-desc {
        display: block;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      .desc-open {
        display: block;
        width: 30px;
        font-size: 12px;
        color: #405f8f;
        background: #fff;
      }
    }
  }

  .sand-v,
  .video-v {
    width: 375px;
    margin-top: 20px;
    .sand-title,
    .video-title {
      width: 331px;
      position: relative;
      margin-left: 16px;
      margin-bottom: 10px;
      .sand-title-text,
      .video-title-text {
        z-index: 10;
        height: 24px;
        font-size: 18px;
        font-weight: bold;
        color: #1d2541;
        position: relative;
      }
      .sand-title-img,
      .video-title-img {
        width: 34px;
        position: absolute;
        left: 40px;
        bottom: 2px;
        z-index: 2;
      }
    }
    .sand-scroll-view,
    .video-scroll-view {
      width: 375px;
      // height: 262px;
      padding-right: 16px;
      box-sizing: border-box;
      overflow-x: scroll;
      overflow-y: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .sand-content,
      .video-content {
        width: 343px;
        height: 262px;
        padding: 16px;
        box-sizing: border-box;
        margin-left: 16px;
        background-color: #fff;
        border-radius: 4px;
        .sand-content-title {
          margin-bottom: 10px;
          width: 343px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #1d2541;
          display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
          -webkit-line-clamp: 1; /*显示的行数；如果要设置2行加...则设置为2*/
          overflow: hidden; /*超出的文本隐藏*/
          text-overflow: ellipsis; /* 溢出用省略号*/
          -webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/
        }
        .sand-box,
        .video-box {
          width: 311px;
          height: 198px;
          background-color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 0;
          border-radius: 4px;
          position: relative;
          .sand-img,
          .video-img {
            max-width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .sand-logo,
          .video-logo {
            width: 48px;
            height: 48px;
            position: absolute;
            top: 75px;
            left: 132px;
          }
        }
      }
      .sand-content-2 {
        width: 164px;
        height: 262px;
        .sand-content-title {
          width: 132px;
        }
        .sand-box {
          width: 132px;
          height: 198px;
          .sand-logo,
          .video-logo {
            top: 75px;
            left: 42px;
          }
        }
      }
      .sand-content-3 {
        width: 132px;
        height: 211px;
        .sand-content-title {
          width: 100px;
        }
        .sand-box {
          width: 100px;
          height: 147px;
          .sand-logo,
          .video-logo {
            top: 50px;
            left: 26px;
          }
        }
      }
      .video-more {
        width: 280px;
        height: 231px;
        .video-box {
          width: 248px;
          height: 100%;
          .video-logo {
            top: 76px;
            left: 100px;
          }
        }
      }
    }
  }

  .article-v {
    margin-left: 16px;
    margin-top: 20px;
    border-radius: 4px;
    box-sizing: border-box;
    justify-content: center;
    width: 343px;
    .article-title {
      width: 343px;
      position: relative;
      .article-title-text {
        z-index: 10;
        height: 24px;
        font-size: 18px;
        font-weight: bold;
        color: #1d2541;
        position: relative;
      }
      .article-title-img {
        width: 34px;
        position: absolute;
        left: 40px;
        bottom: 2px;
        z-index: 2;
      }
    }
    .vr-content {
      width: 343px;
      margin-top: 14px;
      background: #fff;
      padding: 16px;
      box-sizing: border-box;
      border-radius: 4px;
      .title {
        font-size: 16px;
        color: #1d2541;
        line-height: 22px;
        margin-bottom: 10px;
      }
      .vr-box {
        width: 311px;
        height: 198px;
        background: #000000;
        border-radius: 4px;
        position: relative;
        display: flex;
        align-content: center;
        justify-content: center;
        .vr-img {
          max-width: 100%;
          height: 100%;
        }
        .vr-logo {
          position: absolute;
          width: 48px;
          height: 48px;
          top: 75px;
          left: 132px;
        }
      }
    }
    .article-content {
      width: 343px;
      // height: 51px;
      background: #fff;
      padding: 16px;
      box-sizing: border-box;
      margin-top: 14px;
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      justify-content: flex-start;
      border-radius: 4px;
      .article-content-img {
        width: 60px;
        height: 70px;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        border-radius: 4px;
        .article-img {
          object-fit: contain;
          width: 60px;
          height: 70px;
        }
      }
      .article-content-text {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .article-content-title {
          font-size: 16px;
          color: #1d2541;
          line-height: 22px;
          display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
          -webkit-line-clamp: 2; /*显示的行数；如果要设置2行加...则设置为2*/
          overflow: hidden; /*超出的文本隐藏*/
          text-overflow: ellipsis; /* 溢出用省略号*/
          -webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/
        }
        .article-content-abstract {
          font-size: 12px;
          color: #86858b;
          line-height: 20px;
          display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
          -webkit-line-clamp: 1; /*显示的行数；如果要设置2行加...则设置为2*/
          overflow: hidden; /*超出的文本隐藏*/
          text-overflow: ellipsis; /* 溢出用省略号*/
          -webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/
        }
      }
    }
  }

  .photos-v {
    margin-left: 16px;
    margin-top: 20px;
    border-radius: 4px;
    box-sizing: border-box;
    justify-content: center;
    width: 343px;

    .photo-bg {
      margin-top: 16px;
      width: 343px;
      height: 118px;
    }

    .photos-title {
      width: 343px;
      position: relative;
      .photos-title-text {
        z-index: 10;
        height: 24px;
        font-size: 18px;
        font-weight: bold;
        color: #1d2541;
        position: relative;
      }
      .photos-img {
        width: 34px;
        position: absolute;
        left: 40px;
        bottom: 2px;
        z-index: 2;
      }
      .bg {
        position: absolute;
        left: 36px;
        z-index: 1;
        bottom: 6px;
        width: 34px;
        height: 5px;
        background: linear-gradient(to bottom, #578fec, #c5dbff);
        border-radius: 6px;
      }
    }

    .photo {
      width: 343px;
      margin-top: 12px;
      border-radius: 4px;
    }
    
  }

  .project-v {
    margin-left: 16px;
    margin-top: 20px;
    border-radius: 4px;
    box-sizing: border-box;
    justify-content: center;
    width: 343px;
		.project-title {
      width: 343px;
      position: relative;
			.project-title-text {
        z-index: 10;
        height: 24px;
        font-size: 18px;
        font-weight: bold;
        color: #1d2541;
        position: relative;
			}
			.project-title-img {
        width: 34px;
        position: absolute;
        left: 36px;
        bottom: 2px;
        z-index: 2;
			}
		}
		.project-content {
			width: 343px;
			height: 116px;
			background-color: #FFFFFF;
			border-radius: 4px;
			margin-top: 14px;
			padding: 10px;
      display: flex;
			flex-direction: row;
      box-sizing: border-box;
      align-content: flex-start;
      justify-content: flex-start;
          position: relative;
			.project-img {
				width: 128px;
				height: 96px;
				.img-view {
					width: 128px;
					height: 96px;
					border-radius: 6px;
				}
			}
			.project-info {
				width: 183px;
				margin-left: 12px;
        display: flex;
        flex-direction: column;
				justify-content: space-between;
				.info-title {
					font-size: 16px;
					font-weight: bold;
					color: #303133;
					line-height: 22px;
				}
				.info-price {
          display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					.price-text {
						font-size: 12px;
						font-weight: 400;
						color: #F35239;
						line-height: 17px;
					}
					.price-num {
						font-size: 20px;
						font-weight: bold;
						color: #F35239;
						line-height: 24px;
					}
					.price-unit {
						color: #C2C3C6;
						margin-left: 3px;
					}
				}
				.info-address {
					font-size: 12px;
					color: #606266;
					line-height: 17px;
          display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
          -webkit-line-clamp: 1; /*显示的行数；如果要设置2行加...则设置为2*/
          overflow: hidden; /*超出的文本隐藏*/
          text-overflow: ellipsis; /* 溢出用省略号*/
          -webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/
				}
				.info-label {
          display: flex;
					flex-direction: row;
					justify-content: flex-start;
					.label-item {
						display: block;
						font-size: 10px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #6085E3;
						background-color: rgba(96, 133, 227, 0.08);
						border-radius: 2px;
						padding: 1px 3px;
						line-height: 14px;
						margin-right: 4px;
					}
				}
			}
          .pro-weapp {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 2;
            background-color: rgba(0, 0, 0, 0);
            #launch-btn-pro,
            .maxbox-pro {
              width: 310px;
              height: 96px;
            }
          }
		}
	}

  .no-more {
    text-align: center;
    font-size: 12px;
    color: #86858b;
    padding-top: 30px;
    padding-bottom: 10px;
  }

  .share-view {
    position: fixed;
    z-index: 20;
    width: 100%;
    bottom: 0;
    left: 0;
    .share-top {
      width: 375px;
      height: 8px;
      background-color: rgba(0, 0, 0, 0);
    }

    .saler-img {
      object-fit: cover;
      position: fixed;
      z-index: 10;
      width: 50px;
      height: 50px;
      position: absolute;
      border: 2px solid #ffffff;
      border-radius: 25px;
      left: 16px;
      top: 2px;
    }

    .share-body {
      position: fixed;
      padding-top: 4px;
      position: relative;
      box-sizing: border-box;
      width: 375px;
      /*padding-left: 30px;*/
      height: 50px;
      box-sizing: border-box;
      justify-content: center;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.06);
      .share-filter {
        width: 100%;
        height: 100%;
        filter: blur(6px);
        -webkit-filter: blur(6px);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      .share-content {
        z-index: 2;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }
      .saler-info {
        &:hover {
          opacity: 0.8;
        }
        position: relative;
        margin-right: 6px;
        width: 132px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 8px;
        border: 1px solid #d0d0d0;
        box-sizing: border-box;
        justify-content: center;
        padding-left: 32px;
        overflow: hidden;
        .name-v {
          align-items: center;
          margin-bottom: 4px;
          .name {
            max-width: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: keep-all;
            font-size: 12px;
            font-weight: bold;
            color: #1d2541;
          }
          .role-name {
            width: 32px;
            height: 10px;
            margin-left: 3px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              width: 32px;
              height: 10px;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              text-align: center;
              font-size: 6px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #6a4e38;
              line-height: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .icon {
            width: 32px;
            height: 10px;
          }
        }

        .name-bottom {
          align-items: center;
          .name-bottom-text {
            font-size: 8px;
            color: #86858b;
          }

          .name-bottom-icon {
            margin-left: 3px;
            width: 8px;
            height: 8px;
          }
        }

        .ground {
          position: absolute;
          z-index: 1;
        }

        .open-weapp {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2;
          background-color: rgba(0, 0, 0, 0);
          #launch-btn,
          .maxbox {
            width: 100%;
            height: 100%;
          }
        }
      }
      .act-btn {
        width: 104px;
        height: 40px;
        /*flex: 1;*/
        margin: 0px 10px;
        border: 2px solid #CDCFD3;
        border-radius: 8px;
        filter: blur(0);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .share-bt-icon {
          width: 12px;
          height: 12px;
        }

        .text {
          font-weight: 500;
          font-size: 12px;
          color: #1D2541;
          line-height: 40px;
          text-align: center;
          margin-left: 4px;
        }

        &.add-user {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          border: none;
          .share-bt-icon {
            width: 22px;
            height: 22px;
          }
          .text {
            line-height: 12px;
            margin-left: 0;
            margin-top: 4px;
          }
        }

      }
      .share-bt-a {
        width: 104px;
        margin: 0px 10px;
        &:hover {
          opacity: 0.8;
        }
        .share-bt {
          align-items: center;
          width: 100%;
          height: 40px;
          background-color: #2858c8;
          border-radius: 8px;
          justify-content: center;

          .share-bt-icon {
            width: 12px;
            height: 12px;
          }

          .share-bt-text {
            font-size: 12px;
            margin-left: 3px;
            color: #ffffff;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.video-player-v {
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  .loading-v {
    color: #fff !important;
    padding: 12px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .close-video {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .show-img {
    width: 375px;
  }
}

.safe-bottom {
  height: 78px !important;
}

/deep/.van-loading__text {
  color: #fff !important;
}
/deep/.video-player {
  width: 100vw;
}
.video-js .vjs-big-play-button {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  z-index: 100;
  background-color: #ffffff;
  border: solid 1px #979797;
}
</style>
